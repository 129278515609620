<template>
  <div class="all-video-subjects-page">
    <v-row justify="center">
      <v-col md="6">
        <v-card class="mx-3 mt-3 py-5 px-3" elevation="0" color="primary">
          <h3 class="white--text">
            Hi {{ currentUser.full_name.split(" ")[0] }},<br />What would you
            like to watch today?
          </h3>
        </v-card>

        <h3 class="mx-3 mt-3">Your Subjects</h3>

        <intract-smart-list
          disable-search
          ref="all-video-subjects-smart-list"
          :endpoint="getAllSubjectsEndpoint"
          :item-options="{ title: 'name' }"
        >
          <template v-slot:list-item="{ item: subjectItem }">
            <div>
              <v-list-item
                class="non-focused"
                @click="
                  $router.push({
                    name: 'AllVideoModules',
                    params: {
                      subjectId: subjectItem.id,
                      subjectName: subjectItem.name,
                    },
                  })
                "
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium ma-0">{{ subjectItem.name }}</v-list-item-title>
                  <v-list-item-subtitle class="mt-1">{{
                    subjectItem.room ? subjectItem.room.sections: "Extra Subject"
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon
                  ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
                >
              </v-list-item>
              <v-divider class="mx-4"></v-divider>
            </div>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>

    <!-- <choose-room-dialog
      :visible="!selectedRoom"
      @roomSelected="(r) => (selectedRoom = r)"
    /> -->

    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
        }
      "
    />
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import { mapActions, mapGetters } from "vuex";
import EventBus from "@utils/event_bus";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";

export default {
  name: "AllVideoSubjects",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],

  components: {
    ChooseRoomDialog,
    IntractSmartList,
  },

  data() {
    return {
      selectedRoom: null,
      showRoomDialog: false,
    };
  },

  beforeRouteLeave(to, from, next) {
    if (to.name != "AllVideoModules") {
      //kill component
      EventBus.$emit("keep_alive__destroy_view", "AllVideoSubjects");
    }
    next();
  },

  computed: {
    ...mapGetters(["currentUser", "accessToken"]),

    // essentials() {
    //     return {
    //         accessToken: this.accessToken,
    //         handleErrorsFunction: this.handleApiError,
    //         setLoading: this.setLoading,
    //     };
    // },

    getAllSubjectsEndpoint() {
      if (
        (this.currentUser.is_admin || this.currentUser.is_student) &&
        this.selectedRoom
      ) {
        return this.Helper.addUrlParams(this.endpoints.subjectViewSet, [
          "room=" + this.selectedRoom.id,
        ]);
      } else if (this.currentUser.is_faculty && !this.currentUser.is_admin) {
        return this.Helper.addUrlParams(this.endpoints.subjectViewSet, [
          "faculty=" + this.currentUser.id,
        ]);
      } else return null;
    },
  },

  methods: {
    ...mapActions(["showSnackbar", "setLoading"]),
    async onRefresh() {
        if(this.$refs['all-video-subjects-smart-list'])this.$refs['all-video-subjects-smart-list'].getItems();
    },
  },

  created() {
    this.showRoomDialog = this.currentUser.is_admin;
  },
};
</script>

<style>
</style>