var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-video-subjects-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-card',{staticClass:"mx-3 mt-3 py-5 px-3",attrs:{"elevation":"0","color":"primary"}},[_c('h3',{staticClass:"white--text"},[_vm._v(" Hi "+_vm._s(_vm.currentUser.full_name.split(" ")[0])+","),_c('br'),_vm._v("What would you like to watch today? ")])]),_c('h3',{staticClass:"mx-3 mt-3"},[_vm._v("Your Subjects")]),_c('intract-smart-list',{ref:"all-video-subjects-smart-list",attrs:{"disable-search":"","endpoint":_vm.getAllSubjectsEndpoint,"item-options":{ title: 'name' }},scopedSlots:_vm._u([{key:"list-item",fn:function(ref){
var subjectItem = ref.item;
return [_c('div',[_c('v-list-item',{staticClass:"non-focused",on:{"click":function($event){return _vm.$router.push({
                  name: 'AllVideoModules',
                  params: {
                    subjectId: subjectItem.id,
                    subjectName: subjectItem.name,
                  },
                })}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"font-weight-medium ma-0"},[_vm._v(_vm._s(subjectItem.name))]),_c('v-list-item-subtitle',{staticClass:"mt-1"},[_vm._v(_vm._s(subjectItem.room ? subjectItem.room.sections: "Extra Subject"))])],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)],1),_c('v-divider',{staticClass:"mx-4"})],1)]}}])})],1)],1),_c('choose-room-dialog',{attrs:{"visible":_vm.showRoomDialog},on:{"roomSelected":function (r) {
        _vm.selectedRoom = r;
        _vm.showRoomDialog = false;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }